import { render, staticRenderFns } from "./ResetCredenzialiServizioSpSa.vue?vue&type=template&id=70b729f7"
import script from "./ResetCredenzialiServizioSpSa.vue?vue&type=script&lang=js"
export * from "./ResetCredenzialiServizioSpSa.vue?vue&type=script&lang=js"
import style0 from "@/styles/custom/views/_reset_credenziali_servizio.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports