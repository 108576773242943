<template>
  <div id="step-verifica-password">
    <p id="info-cred-crypt">
      Per procedere a reimpostare le credenziali STS è richiesta la verifica
      della password.
    </p>
    <label for="password"
      >Digitare la password utilizzata per accedere alla web application
      Mida4</label
    >
    <div class="input-w-icon">
      <input
        :type="showPwd ? 'text' : 'password'"
        name="password"
        autocomplete="off"
        required
        v-model="password"
        ref="campoPassword"
      />
      <font-awesome-icon
        class="show-pw-ico"
        :icon="showPwd ? 'eye-slash' : 'eye'"
        @click="showPwd = !showPwd"
      />
    </div>
    <button id="verifica-password" @click.prevent="verifica">
      Verifica password
    </button>
  </div>
</template>

<script>
import { R } from "mida4-web-app-utils";
export default {
  data() {
    return {
      showPwd: false,
      password: null,
    };
  },
  methods: {
    verifica() {
      if (R.isEmpty(this.password) || R.isNil(this.password)) {
        this.$refs.campoPassword.classList.add("has-error");
        this.$refs.campoPassword.addEventListener(
          "input",
          (e) => e.target.classList.remove("has-error"),
          { once: true }
        );
        return false;
      }
      this.$emit("verificaPassword", this.password);
    },
  },
};
</script>

<style>
</style>