<template>
  <v-popover placement="top-start" popoverClass="reset-credenziali-servizio">
    <span class="info tooltip-target b3" :data-campo="campo">?</span>
    <template slot="popover">
      <p v-html="msg" />
    </template>
  </v-popover>
</template>

<script>
import { VPopover } from "v-tooltip";
export default {
  components: {
    VPopover,
  },
  props: ["msg", "campo"],
};
</script>

<style>
</style>