<template>
  <LayoutSpSa>
    <section id="reset-credenziali-servizio">
      <h2>Modifica Credenziali Servizio Tessera Sanitaria</h2>
      <VerificaPasswordSpSa
        v-if="!passwordVerificata"
        @verificaPassword="verificaPassword"
      />
      <div v-else-if="!credenzialiSalvate">
        <p id="info-cred-crypt">
          Le credenziali qui inserite verranno registrate nella base dati in un
          formato criptato indecifrabile dai nostri operatori.
        </p>
        <p id="info-required">
          I campi contrassegnati dall'asterisco sono obbligatori
        </p>
        <FormSchemaNative
          id="form-reset-credenziali"
          ref="formResetCredenziali"
          :schema="SchemaResetCredenzialiSTS"
          v-model="datiReset"
          v-noac
        />
        <!-- <VueRecaptcha 
          id="recaptcha-container"
          ref="recaptcha"
          :sitekey="siteKey"
          :loadRecaptchaScript="true"
          @verify="onCaptchaVerified"
          @expired="onCaptchaExpired"/> -->
        <button
          id="reimposta-credenziali"
          @click.prevent="reimpostaCredenziali"
        >
          Salvare credenziali
        </button>
      </div>
      <ConfermaSalvaCredSpSa v-else />
      <!-- <div v-else>
        <p>
          Le credenziali sono state salvate correttamente. E' ora possibile
          inviare i documenti di spesa al Servizio Tessera Sanitaria.
        </p>
        <p>A breve verrai reindirizzato alla pagina home.</p>
        <router-link to="/" tag="button">Torna alla home</router-link>
      </div> -->
    </section>
  </LayoutSpSa>
</template>
<script>
/* eslint-disable */
/**
 * quando arrivi sulla pagina ti chiede la password
 * inserisci - procedi - viene verificata la pw
 *
 * se NO logout
 * se OK
 *
 * mostra maschera inserimento
 *
 * quando clicchi su salva
 *
 * test fattura farlocca
 *
 * se OK salva
 * se NO torna all'inserimento mostrando l'errore
 * */

const NAME = "ResetCredenzialiServizioSpSa";
const SEPARATORE = "CRED_CHK:::";

import StatusCodes from "http-status-codes";
import { R, Forms } from "mida4-web-app-utils";

import Vue from "vue";

import FormSchemaNative from "@formschema/native";
import LayoutSpSa from "@/components/_layout/LayoutSpSa";
import VerificaPasswordSpSa from "@/components/ResetCredenzialiServizioSpSa/VerificaPasswordSpSa";
import ConfermaSalvaCredSpSa from "@/components/ResetCredenzialiServizioSpSa/ConfermaSalvaCredSpSa";

import SchemaResetCredenzialiSTS from "@/entities/schemas/utente-reset-credenziali-servizio.json";
import { mapGetters, mapActions } from "vuex";

import { tipsCredenziali } from "@/entities/enums/tips-credenziali";

import CampiResetToolTip from "@/components/ResetCredenzialiServizioSpSa/CampiResetToolTip";
import Invianti from "@/entities/enums/invianti";

export default {
  name: NAME,
  components: {
    LayoutSpSa,
    FormSchemaNative,
    VerificaPasswordSpSa,
    ConfermaSalvaCredSpSa,
    // VueRecaptcha
  },
  data() {
    return {
      NAME: NAME,
      SchemaResetCredenzialiSTS: SchemaResetCredenzialiSTS,
      passwordVerificata: false,
      credenzialiSalvate: false,
      error: false,
      datiReset: {
        password: null,
        stsUserID: null,
        stsPassword: null,
        stsPIN: null,
        cfTitolare: null,
        codiceRegione: null,
        codiceASL: null,
        codiceSSA: null,
      },
      tips: tipsCredenziali,
      openTip: null,
      updated: false,
    };
  },
  created() {
    // assegno dei valori di default se ho i dati
    [
      "cfTitolare",
      "stsUserID",
      "codiceRegione",
      "codiceASL",
      "codiceSSA",
    ].forEach((lbl) => {
      this.datiReset[lbl] = this.user[lbl];
    });
    if (
      !this.isStruttura &&
      (R.isNil(this.datiReset.stsUserID) || R.isEmpty(this.datiReset.stsUserID))
    ) {
      this.datiReset.stsUserID = this.user.codiceFiscale;
    }
  },
  updated() {
    if (!this.updated) {
      Forms.riordina(this, {
        formResetCredenziali: [
          {
            id: "codici-obbligatori",
            before: '[data-fs-field^="cfTitolare"]',
            content: ["stsUserID", "stsPIN", "stsPassword"],
            gridTemplateColumns: "repeat(3, 1fr)",
          },
          {
            id: "codici-struttura",
            content: ["codiceRegione", "codiceASL", "codiceSSA"],
            gridTemplateColumns: "repeat(3, 1fr)",
          },
        ],
      });
      this.updated = true;

      this.$nextTick(() => {
        document.querySelectorAll("label").forEach((e) => {
          const campo = e.attributes.for.value.split("-")[0];
          const txt = e.innerText;
          const isRequired = e.closest("[data-fs-required=true]") !== null;
          var TipClass = Vue.extend(CampiResetToolTip);
          var instance = new TipClass({
            propsData: {
              msg: tipsCredenziali[campo][
                this.isStruttura ? "struttura" : "default"
              ],
              campo: campo,
            },
          });
          instance.$mount();
          const txtEl = document.createElement("span");
          txtEl.classList.add("content");
          txtEl.innerText = txt;
          e.innerText = "";
          e.append(txtEl);
          if (isRequired) {
            const reqStar = document.createElement("span");
            reqStar.innerText = "*";
            reqStar.classList.add("required-star");
            e.append(reqStar);
          }
          e.append(instance.$el);
        });
      });
    } else if (!this.credenzialiSalvate) {
      this.$nextTick(() => {
        const frm = document.querySelector("#form-reset-credenziali form");

        const divInfoPDF =
          "E' possibile ottenere questi dati accedendo con le credenziali della struttura nel Sistema Tessera Sanitaria → Profilo utente → (in alto a destra) Stampa Credenziali. Sono contenuti nel PDF che verrà generato.";

        const gruppo = document.querySelector("#codici-struttura");
        const divInfoStruttura = document.createElement("div");

        divInfoStruttura.id = "info-struttura";
        divInfoStruttura.innerHTML = `I codici che seguono sono richiesti <strong>esclusivamente</strong> per:
<ul>
  <li><strong>strutture accreditate,</li>
  <li>strutture autorizzate,</li>
  <li>strutture accreditate alla vendita al dettaglio,</li>
  <li>ottici,</li>
  <li>farmacie e parafarmacie.</strong></li>
</ul>
I dati sono contenuti nello stesso PDF con il quale è stato ottenuto il PIN e sono indicati con il nome “Codice Proprietario”
        `;
        frm.insertBefore(divInfoStruttura, gruppo);
      });
    }
  },
  computed: {
    ...mapGetters({
      user: "user/getData",
      isStruttura: "user/isStruttura",
    }),
    hasDatiSTS() {
      return [
        this.datiReset.cfTitolare,
        this.datiReset.codiceRegione,
        this.datiReset.codiceASL,
        this.datiReset.codiceSSA,
      ].every((i) => !(R.isNil(i) || R.isEmpty(i)));
    },
    parzialmenteCompilato() {
      const nullOrEmpty = (i) => R.isNil(i) || R.isEmpty(i);
      const datiBaseIncompleti = [
        this.datiReset.stsUserID,
        this.datiReset.stsPassword,
      ].some(nullOrEmpty);

      const datiStrutturaincompleti = this.isStruttura && !this.hasDatiSTS;
      return datiBaseIncompleti || datiStrutturaincompleti;
    },
  },
  methods: {
    ...mapActions({
      setCredenzialiSTS: "auth/setCredenzialiSTS",
      setDatiSTS: "auth/setDatiSTS",
    }),
    async verificaPassword(pw) {
      this.datiReset.password = pw;
      this.toggleLoader(true);
      const res = await this.$api.verificaPassword(this.datiReset.password);
      if (res.status === StatusCodes.OK) {
        this.passwordVerificata = true;
      } else {
        this.toggleLoader(false);
        this.showError({
          title: "Password Errata",
          message:
            "La password inserita non è valida. Verrete reindirizzati alla pagina di login.",
          didClose: () => {
            setTimeout(() => {
              location.href = location.pathname;
            }, 1000);
          },
        });
      }
      // else this.$log.debug('NO')
      this.toggleLoader(false);
    },
    async reimpostaCredenziali() {
      if (this.parzialmenteCompilato) {
        this.showError({
          title: "Impossibile procedere",
          message: "Dati incompleti",
        });
        return;
      }
      this.toggleLoader(true);
      const res = await this.$api.reimpostaCredenzialiServizio(this.datiReset);
      if (res.status === StatusCodes.OK) {
        this.credenzialiSalvate = true;
        this.setCredenzialiSTS({
          stsPassword: this.datiReset.stsPassword,
          stsPIN: this.datiReset.stsPIN,
        });
        if (this.hasDatiSTS) {
          this.setDatiSTS({
            cfTitolare: this.datiReset.cfTitolare,
            codiceRegione: this.datiReset.codiceRegione,
            codiceASL: this.datiReset.codiceASL,
            codiceSSA: this.datiReset.codiceSSA,
          });
        }
      } else {
        this.toggleLoader(false);
        const body = await res.json();
        // this.$log.debug('c', JSON.stringify(body, null, 2))
        this.showError({ message: body.message.split(SEPARATORE).pop() });
        return;
      }
      this.toggleLoader(false);
      setTimeout(() => {
        this.$router.push({ name: "Home" });
      }, 1000);
    },
  },
  notifications: {
    showError: {
      title: "Errore - nuove credenziali NON registrate",
      message: null,
      type: "error",
      html: null,
    },
  },
};
</script>
<style src="@/styles/custom/views/_reset_credenziali_servizio.scss" lang="scss"></style>