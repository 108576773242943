export const tipsCredenziali = {
  stsUserID: {
    struttura:
      "Codice di cinque cifre utilizzato per accedere al portale Tessera Sanitaria.",
    default:
      "Codice utilizzato per accedere al portale Tessera Sanitaria: corrisponde al Codice Fiscale dell'utente",
  },
  stsPassword: {
    struttura: "Password utilizzata per accedere al portale Tessera Sanitaria",
    default: "Password utilizzata per accedere al portale Tessera Sanitaria",
  },
  stsPIN: {
    struttura: "PIN del Sistema Tessera Sanitaria.",
    default: "PIN del Sistema Tessera Sanitaria.",
  },
  cfTitolare: {
    struttura:
      "Codice fiscale del titolare così come registrato nel Sistema Tessera Sanitaria",
    default: "Corrisponde al codice fiscale dell'utente",
  },
  // codici richiesti solo per strutture accreditate, strutture autorizzate, strutture accreditate alla vendita al dettaglio, ottici, farmacie e parafarmacie.
  codiceRegione: {
    struttura:
      "La prima sezione del codice proprietario riportato sul pdf (<strong>XXX</strong> nella sequenza <strong>XXX</strong>-YYY-ZZZZZZ)",
    default:
      "La prima sezione del codice proprietario riportato sul pdf (<strong>XXX</strong> nella sequenza <strong>XXX</strong>-YYY-ZZZZZZ)",
  },
  codiceASL: {
    struttura:
      "La seconda sezione del codice proprietario riportato sul pdf (<strong>YYY</strong> nella sequenza XXX-<strong>YYY</strong>-ZZZZZZ)",
    default:
      "La seconda sezione del codice proprietario riportato sul pdf (<strong>YYY</strong> nella sequenza XXX-<strong>YYY</strong>-ZZZZZZ)",
  },
  codiceSSA: {
    struttura:
      "La terza sezione del codice proprietario riportato sul pdf (<strong>ZZZZZZ</strong> nella sequenza XXX-YYY-<strong>ZZZZZZ</strong>)",
    default:
      "La terza sezione del codice proprietario riportato sul pdf (<strong>ZZZZZZ</strong> nella sequenza XXX-YYY-<strong>ZZZZZZ</strong>)",
  },
};
